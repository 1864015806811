/* fonts import */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

:root {
  --light-purple: #1D1933;
  --purple: #6f4ef2;
  --white: #ffffff;
  --body-bg: #131129;
  --theme-box-shadow: 0px 0px 10px 5px #0000000D;
  --secondary-color: #e3ddfc;
  --border-radius-10px: 10px;
  --pink: #f73164;
  --border-light: #ffffff14;
}

body {
  font-size: 15px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  background-color: var(--body-bg) !important;
  color: var(--secondary-color);
}

a {
  color: var(--secondary-color);
}


::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
  background-color: transparent;
}




.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ================================= */

.auth-inner-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.page-layout-main {
  display: flex;
}

.page-layout-right-main {
  flex: 1;
  width: 100%;
  background-color: var(--body-bg);
  transition: all 0.5s ease;
}


.page-layout-left-main {
  height: 100vh;
  max-width: 250px;
  flex: 0 0 250px;
  width: 100%;
  background-color: var(--light-purple);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 55px;
  padding: 15px 0px;
  z-index: 10;
}

.page-content-main {
  padding: 82px 20px 20px 280px;
  transition: all 0.5s ease;
}

.page-layout-main-deactive .page-content-main {
  padding: 82px 20px 20px 20px;
}

.page-layout-left-main.page-layout-left-deactive {
  max-width: 0px;
  flex: 0 0 0px;
}

body .chakra-menu__menu-list {
  background-color: var(--light-purple);
  border: 0;
  min-width: max-content;
}

body .chakra-menu__menuitem {
  background-color: var(--light-purple);
}

.common-card {
  background-color: var(--light-purple);
  border-radius: var(--border-radius-10px);
  -webkit-border-radius: var(--border-radius-10px);
  -moz-border-radius: var(--border-radius-10px);
  -ms-border-radius: var(--border-radius-10px);
  -o-border-radius: var(--border-radius-10px);
  box-shadow: var(--theme-box-shadow);
  padding: 15px;
  margin-bottom: 15px;
}

.common-card:last-child {
  margin-bottom: 0;
}

@media (max-width: 1249px) {}

@media (max-width: 1023px) {


  .header-logo-flex {
    gap: 10px;
    justify-content: space-between;
    width: 100%;
  }

  .page-layout-main .page-content-main {
    padding: 82px 20px 20px 20px;
  }

  .common-tabs-main .common-tabs-list {
    min-width: 350px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }

  .modal-dialog {
    min-width: 350px;
  }

  /* .view-content-flex {
    gap: 10px;
    border-bottom: 1px solid #ffffff08;
    padding-bottom: 5px;
    margin-bottom: 5px;
  } */

  .modal-heading {
    font-size: 16px;
    line-height: 21px;
  }

  .page-title {
    font-size: 17px;
  }

  .common-tabs-main .common-tabs-tab {
    padding: 10px;
    min-width: 180px;
  }

  .dashboard-card-title {
    font-size: 15px;
    line-height: 20px;
  }

  .dashboard-card-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .dashboard-card-row {
    grid-template-columns: repeat(1, 1fr);
  }

  .date-range-input-main {
    width: 100%;
  }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}